import { useLocation, useNavigate } from 'react-router-dom';
import ReactRoutes from './components/shared/ReactRoutes';
import { useState, useEffect, lazy } from 'react';
import useSessionInfo from './data/queryHooks/useSessionInfo';
import useFeatureFlags from './data/queryHooks/useFeatureFlags';
import { flowPath } from './scripts/flowPath';
import { privateRoutes, publicRoutes, nonFlowRoutes } from './scripts/routes';
import { sessionTracker } from 'ash-session-tracker';
import { SessionStorageKey } from './scripts/miscConstants';
import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';
import AppContext from './components/shared/AppContext';

const IntercomChat = lazy(() =>
	import('./components/intercomChat/IntercomChat')
);

const App = () => {
	const { isLoggedIn, hasProgram, ssId, flows } = useSessionInfo();
	const { intercomChatEnabled } = useFeatureFlags();
	const location = useLocation();
	const navigate = useNavigate();
	const [appFlows, setAppFlows] = useState(flows);

	useEffect(() => {
		const whitelist = [
			'events.data.microsoft.com',
			'api.flightproxy.skype.com',
		];
		sessionTracker(isLoggedIn, { whitelist });
	}, [isLoggedIn]);

	useEffect(() => {
		const isPrivateRoute = privateRoutes.find(
			route => location.pathname && location.pathname.includes(route)
		);

		const isPublicRoute = publicRoutes.find(
			route => location.pathname && location.pathname.includes(route)
		);

		// non-logged-in user accessing private routes will be redirected to login page
		if (isPrivateRoute && !isLoggedIn) {
			window.location.href = '/identity/login';
		}

		// logged-in user accessing public routes will be redirected to dashboard page
		if (isPublicRoute && isLoggedIn) {
			navigate('/');
		}

		// logged-in user but doesn't belong to any program will be reidrected to 500 page
		if (isLoggedIn && !hasProgram) {
			navigate('/500');
		}

		if (isLoggedIn) {
			// when user is logged in and on a page other than the non-completed flow page, and the flow page can't be skipped
			// then redirect user to that flow page
			const userFlowData =
				JSON.parse(sessionStorage.getItem(SessionStorageKey.userFlow)) &&
				JSON.parse(sessionStorage.getItem(SessionStorageKey.userFlow))[ssId];
			const userFlow = userFlowData || appFlows;
			let url = '';
			let isReactPage;
			let canSkip = true;

			if (Array.isArray(userFlow) && userFlow.length > 0) {
				// find the first non-completed flow and get whether canSkip
				const firstFlow = userFlow.find(item => !item.isCompleted);
				canSkip = firstFlow ? firstFlow.canSkip : true;
				const firstFlowName = firstFlow ? firstFlow.flow : '';
				// get url and isReactPage for the first non-completed flow from flowPath file
				const firstFlowPath =
					flowPath.filter(item => item.flow === firstFlowName) || [];

				if (firstFlowPath.length > 0) {
					url = firstFlowPath[0].url;
					isReactPage = firstFlowPath[0].isReactPage;
				}
			}

			const isNonFlowRoute = nonFlowRoutes.find(
				route => location.pathname && location.pathname.includes(route)
			);

			if (
				url &&
				location.pathname &&
				!location.pathname.includes(url) &&
				!isNonFlowRoute &&
				!canSkip
			) {
				// add redirect queryParam to keep track of where user wants to go, so we can redirect user to that page after all flows are completed
				const redirectURL = `?redirect=${location.pathname}`;

				isReactPage
					? navigate(url + redirectURL)
					: (window.location.href = url + redirectURL);
			}
		}
	}, [location, isLoggedIn, ssId, navigate, appFlows, hasProgram]);

	const acceptTerms = () => {
		// update isCompleted to true for terms flow
		const termsFlow = appFlows.filter(item => item.flow === 'Terms')[0];
		termsFlow.isCompleted = true;
		// update state and sessionStorage to new flows
		const remainingFlows = appFlows.filter(item => item.flow !== 'Terms');
		const newFlows = [...remainingFlows, termsFlow];

		setAppFlows(newFlows);

		sessionStorage.setItem(
			SessionStorageKey.userFlow,
			JSON.stringify({ [ssId]: newFlows })
		);

		// find the next flow that can not be skipped after terms and redirect user to next flow
		const nextFlowNotSkippable = remainingFlows.find(item => !item.canSkip);

		// get redirectURL from url 'redirect' query param
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		const redirectURL = params.redirect;

		if (nextFlowNotSkippable) {
			const nextFlowPath =
				flowPath.filter(item => item.flow === nextFlowNotSkippable.flow) || [];

			if (nextFlowPath.length > 0) {
				const { url, isReactPage } = nextFlowPath[0];

				isReactPage ? navigate(url) : (window.location.href = url);
			} else {
				navigate('/');
			}
		} else if (redirectURL) {
			// if no next flow and has redirectURL, take user to redirect page
			// regular expression to check if a string contains a domain
			const regex = /[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*\.[A-Za-z]{2}/;
			// if redirectURL doesn't contain a domain or contains ashcare.com domain, then we do the redirection
			if (
				!regex.test(redirectURL) ||
				(regex.test(redirectURL) && redirectURL.includes('ashcare.com'))
			) {
				window.location.href = redirectURL;
			}
		} else {
			// if no next flow and no redirectURL, default to home page
			navigate('/');
		}
	};

	// TO-DO: if feature flags grow consider refactoring to conditionally wrap with AppContext
	return (
		<EveThemeProvider themeKey="ashcare" themeMode="light">
			{intercomChatEnabled ? (
				<AppContext>
					<ReactRoutes
						acceptTerms={acceptTerms}
					/>
					<IntercomChat
						intercomAppId="cqwegikk"
						timeoutSeconds={600}
						promptBeforeIdleSeconds={30}
					/>
				</AppContext>
			) : (
				<ReactRoutes acceptTerms={acceptTerms} />
			)}
		</EveThemeProvider>
	);
};

export default App;
