import { useQuery } from 'react-query';
import { fetchThreadDetails } from '../apiCalls';
import { QueryKey } from './QueryKeys';

const useFetchThreadsDetails = (enabled) => {
	const { data, error, isLoading } = useQuery(
		QueryKey.GET_THREADS_DETAILS,
		fetchThreadDetails,
		{enabled}
	);

	return { data, error, isLoading };
};

export default useFetchThreadsDetails;

