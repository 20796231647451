import Button from '@mui/material/Button';
import { string, arrayOf, bool, object } from 'prop-types';
import { useNavigate, Link as ReactLink, useLocation } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import NonReactLink from '@mui/material/Link';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { SubLinkType } from '../../../scripts/customPropTypes';
import useSsoRedirect from '../../../data/queryHooks/useSsoRedirect';

export const NavButton = ({
	isExternal,
	isReact,
	href,
	ssoFeature,
	sx,
	subLinks,
	children,
	...props
}) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const ssoRedirect = useSsoRedirect(ssoFeature);
	const open = Boolean(anchorEl);

	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const subLinkIcon = open ? (
		<KeyboardArrowUpOutlinedIcon />
	) : (
		<KeyboardArrowDownOutlinedIcon />
	);

	const getDataTestId = () => {
		let dataTestId;
		if (isExternal) {
			dataTestId = 'ExternalPageNavButton';
		} else if (isReact) {
			dataTestId = 'ReactPageNavButton';
		} else {
			dataTestId = 'EmberPageNavButton';
		}
		return dataTestId;
	};

	const getEndIcon = () => {
		let endIcon;
		if (subLinks?.length > 0) {
			endIcon = subLinkIcon;
		} else if (isExternal) {
			endIcon = <LaunchIcon />;
		}
		return endIcon;
	};

	const getOnClick = event => {
		let onClick;
		if (subLinks?.length > 0) {
			onClick = handleOpen(event);
		} else if (isReact) {
			onClick = navigate(href);
		} else if (ssoFeature) {
			onClick = ssoRedirect();
		} else onClick = null;

		return onClick;
	};

	return (
		<>
			<Button
				data-testid={getDataTestId()}
				href={(!isReact && !ssoFeature) || pathname === href ? href : ''}
				onClick={getOnClick}
				endIcon={getEndIcon()}
				target={isExternal ? '_blank' : ''}
				sx={{ fontWeight: 'fontWeightBold', ...sx }}
				{...props}
			>
				{children}
			</Button>
			{subLinks?.length > 0 && (
				<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
					{subLinks.map(link => {
						const component = link.component ? (
							<Box
								key={link.href}
								sx={{
									a: {
										color: 'text.primary',
										textDecoration: 'none',
									},
								}}
							>
								<ReactLink
									to={link.href}
									reloadDocument={pathname === link.href}
								>
									<MenuItem>{link.label}</MenuItem>
									{/* menu item */}
								</ReactLink>
							</Box>
						) : (
							<NonReactLink
								key={link.href}
								sx={{ color: 'text.primary', textDecoration: 'none' }}
								href={link.href}
							>
								<MenuItem>{link.label}</MenuItem>
							</NonReactLink>
						);
						return component;
					})}
				</Menu>
			)}
		</>
	);
};

NavButton.propTypes = {
	isExternal: bool,
	isReact: bool,
	href: string,
	ssoFeature: string,
	sx: object,
	subLinks: arrayOf(SubLinkType),
	children: string,
};

// Use this to navigate to React pages
export const ReactPageNavButton = ({
	href,
	sx,
	subLinks,
	children,
	...props
}) => (
	<NavButton isReact href={href} sx={sx} subLinks={subLinks} {...props}>
		{children}
	</NavButton>
);
ReactPageNavButton.propTypes = {
	href: string,
	sx: object,
	subLinks: arrayOf(SubLinkType),
	children: string,
};

// Use this to navigate to Ember pages
export const EmberPageNavButton = ({
	href,
	sx,
	subLinks,
	children,
	...props
}) => (
	<NavButton href={href} subLinks={subLinks} sx={sx} {...props}>
		{children}
	</NavButton>
);

EmberPageNavButton.propTypes = {
	href: string,
	sx: object,
	subLinks: arrayOf(SubLinkType),
	children: string,
};

// Use this to navigate to external pages
export const ExternalPageNavButton = ({
	href,
	ssoFeature,
	sx,
	subLinks,
	children,
	...props
}) => (
	<NavButton
		isExternal
		href={href}
		ssoFeature={ssoFeature}
		sx={sx}
		subLinks={subLinks}
		{...props}
	>
		{children}
	</NavButton>
);

/*
 * For a normal external link, pass in the href and leave ssoFeature blank. For an external link
 * that uses single sign-on, pass in the ssoFeature name and leave the href blank.
 */
ExternalPageNavButton.propTypes = {
	href: string,
	ssoFeature: string,
	sx: object,
	subLinks: arrayOf(SubLinkType),
	children: string,
};

export const NavButtonMapping = {
	ReactComponent: ReactPageNavButton,
	EmberComponent: EmberPageNavButton,
	ExternalComponent: ExternalPageNavButton,
};
