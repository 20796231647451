import { lazy } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HeaderLogo from './HeaderLogo';
import UserProfileDropDown from './UserProfileDropDown';
import MobileNavMenu from './MobileNavMenu';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useNavRoutes from '../../../data/queryHooks/useNavRoutes';
import useIsMobile from '../../../utils/customHooks/useIsMobile';
import NavigationItem from './NavigationItem';
import { useNavigate } from 'react-router-dom';
import {
	topBarHeight,
	topBarMobileHeight,
} from '../../../scripts/miscConstants';
import { NavButtonMapping } from '../buttons/NavButtons';
import useSessionInfo from '../../../data/queryHooks/useSessionInfo';
import getApiEnvironment from '../../../utils/environmentHelpers/getAPIEnvironments';
import useFetchThreadsDetails from '../../../data/queryHooks/useFetchThreadsDetails';

const NotificationWidget = lazy(() =>
	import('@ashn/message-center-react/NotificationWidget')
);
const TopBar = () => {
	const navigate = useNavigate();
	const { ssId, secureMessaging } = useSessionInfo();
	const { data } = useFetchThreadsDetails(secureMessaging);
	const { topNavBarRoutes, profileRoutes, secondNavBarRoutes } = useNavRoutes();
	const isMobile = useIsMobile();

	const callToActionProps = {
		defaultStyles: {
			ml: 2,
		},
		color: 'secondary',
		variant: 'contained',
	};

	const normalButtonProps = {
		defaultStyles: {
			ml: 2,
			color: 'text.primary',
		},
	};

	return (
		<AppBar
			variant="outlined"
			elevation={0}
			color="inherit"
			sx={{ border: 'none', boxShadow: '0px 2px 2px -1px rgba(0, 0, 0, 0.07)' }}
		>
			<Container>
				<Toolbar
					sx={{
						justifyContent: 'space-between',
						minHeight: `${
							isMobile ? topBarMobileHeight : topBarHeight
						} !important`,
					}}
				>
					<Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
						{isMobile && secondNavBarRoutes.length > 0 && (
							<MobileNavMenu navBarLinks={secondNavBarRoutes} />
						)}
						<HeaderLogo />
					</Stack>
					<Box>
						{topNavBarRoutes.length > 0 &&
							topNavBarRoutes.map(
								topNavBarLink =>
									// Call to action button is hidden for mobile screens since this
									// button will also be displayed on the home page.
									(!isMobile || topNavBarLink.isCallToAction) && (
										// @ts-ignore
										<NavigationItem
											key={topNavBarLink.href}
											navLink={topNavBarLink}
											{...(topNavBarLink.isCallToAction
												? callToActionProps
												: normalButtonProps)}
											{...NavButtonMapping}
										>
											{topNavBarLink.label}
										</NavigationItem>
									)
							)}
						{profileRoutes && (
							<Grid container>
								{secureMessaging && (
									<Grid item xs={6}>
										<NotificationWidget
											ssId={ssId}
											apiEnv={getApiEnvironment()}
											onWidgetClick={() => {
												navigate('/message-center');
											}}
											threadsDetails={data}
										/>
									</Grid>
								)}
								<Grid item xs={6}>
									<UserProfileDropDown profileRoutes={profileRoutes} />
								</Grid>
							</Grid>
						)}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default TopBar;
